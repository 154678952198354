import React from 'react';
import '../assets/css/Footer.css'; // Import your CSS file for styling

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        <a href="https://www.bigsisters.bc.ca/private-policy/" target="_blank" rel="noopener noreferrer">
          Privacy Policy
        </a>
      </div>
    </footer>
  );
};

export default Footer;
