import React from 'react';
import Select from 'react-select'; // Import Select from react-select
import { DROPDOWN_STYLE, countryOptions } from '../constants'; // Import DROPDOWN_STYLE


class AddressForm extends React.Component {
  render() {
    const {
      formData,
      handleInputChange,
      handleProvinceChange,
      handleCountryChange,
    } = this.props; // Destructure props

    const lowerCaseHref = window.location.href.toLowerCase();
    const isVirtual = lowerCaseHref.includes('virtual');

    return (
      <>

        <div className="col-md-12">
          <div className="form-group">
            <label htmlFor="addressLine1">Address{' '}<span className="required">*</span></label>
            <div className="input-group">
              <input
                type="text"
                className="form-control"
                id="addressLine1"
                placeholder="Address Line 1"
                value={formData.addressLine1}
                onChange={handleInputChange}
                required={true}
              />
            </div>
          </div>
        </div>
        <div className="col-md-12">
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              id="addressLine2"
              placeholder="Address Line 2"
              value={formData.addressLine2}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className="col-md-12">
          <div className="form-group">
            <label htmlFor="city">City<span className="required">*</span></label>
            <input
              type="text"
              className="form-control"
              id="city"
              placeholder="City"
              value={formData.city}
              onChange={handleInputChange}
              required={true}
            />
          </div>
        </div>
        <div className="col-lg-6">
          <div className="form-group">
            <label htmlFor="province">{formData.provinceLabel}{' '}<span className="required">*</span></label>
            <Select
              id="province"
              defaultValue={formData.selectedProvince}
              value={formData.selectedProvince}
              className="form-control"
              options={formData.provinceOptions}
              styles={DROPDOWN_STYLE}
              onChange={handleProvinceChange}
              required={true}
            />
          </div>
        </div>
        <div className="col-lg-6">
          <div className="form-group">
            <label htmlFor="zip">{formData.zipLabel}{' '}<span className="required">*</span></label>
            <input
              type="text"
              className="form-control"
              id="zip"
              placeholder={"Your " + formData.zipLabel}
              value={formData.zip}
              onChange={handleInputChange}
              required={true}
            />
          </div>
        </div>
        <div className="col-md-12">
            <div className="form-group">
              <label htmlFor="country">Country{' '}<span className="required">*</span></label>
              <Select
                id="country"
                defaultValue={formData.selectedCountry}
                value={formData.selectedCountry}
                className="form-control"
                options={countryOptions}
                styles={DROPDOWN_STYLE}
                onChange={handleCountryChange}
                required={true}
              />
            </div>
          </div>
      </>
    );
  }
}

export default AddressForm;
