import React from 'react';
import Select from 'react-select'; // Import Select from react-select
import { DROPDOWN_STYLE, countryOptions } from './constants'; // Import DROPDOWN_STYLE
import SingleChoiceDropdown from './SingleChoiceDropdown';


class BillingForm extends React.Component {
  render() {
    const {
      formData,
      handleInputChange,
      toggleValue,
      handleBillingProvinceChange,
      handleBillingCountryChange
    } = this.props; // Destructure props

    const lowerCaseHref = window.location.href.toLowerCase();
    const isVirtual = lowerCaseHref.includes('virtual');

    return (
      <>
        { !isVirtual && (<>
          <SingleChoiceDropdown 
                      value={formData.billingAddress}
                      toggleValue={(e) => toggleValue(e, "billingAddress")}
                      label="Is this your billing address?"
                      identifier="billingAddress"
                      outerClass="col-md-12"
          />
        </>)}



          {!formData.billingAddress && (<>
            <div className="col-md-12">
            <div className="form-group">
              <label htmlFor="country">Billing Country</label>
              <Select
                id="billing-country"
                defaultValue={formData.billingSelectedCountry}
                value={formData.billingSelectedCountry}
                className="form-control"
                options={countryOptions}
                styles={DROPDOWN_STYLE}
                onChange={handleBillingCountryChange}
              />
            </div>
          </div>
            <div className="col-md-12">
              <div className="form-group">
                <label htmlFor="billingAddressLine1">Billing Address</label>
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    id="billingAddressLine1"
                    placeholder="Billing Address Line 1"
                    value={formData.billingAddressLine1}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  id="billingAddressLine2"
                  placeholder="Billing Address Line 2"
                  value={formData.billingAddressLine2}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <label htmlFor="billingCity">City</label>
                <input
                  type="text"
                  className="form-control"
                  id="billingCity"
                  placeholder="City"
                  value={formData.billingCity}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="col-lg-6">
          <div className="form-group">
            <label htmlFor="province">{formData.billingProvinceLabel}</label>
            <Select
              id="billing-province"
              defaultValue={formData.billingSelectedProvince}
              value={formData.billingSelectedProvince}
              className="form-control"
              options={formData.billingProvinceOptions}
              styles={DROPDOWN_STYLE}
              onChange={handleBillingProvinceChange}
            />
          </div>
        </div>


            <div className="col-lg-6">
              <div className="form-group">
                <label htmlFor="billingZip">{formData.billingZipLabel}</label>
                <input
                  type="text"
                  className="form-control"
                  id="billingZip"
                  placeholder="Zip"
                  value={formData.billingZip}
                  onChange={handleInputChange}
                />
              </div>
            </div>
          </>)}
      </>
    );
  }
}

export default BillingForm;
