import React from 'react';
import '../assets/css/FakeGooglePayButton.css'; // Import your CSS file

const GooglePayButton = ({handleClick}) => {

  return (
    <div className="fake-google-pay-button" onClick={handleClick}>
      <div alt="Google Pay Logo" className="google-pay-logo" />
 
    </div>
  );
};

export default GooglePayButton;
