import React from 'react';
import Select from 'react-select'; // Assuming the Select component is from a library like react-select
import { DROPDOWN_STYLE, wordingOptions } from './constants';
import SingleOption from './SingleOption';
import SingleChoiceDropdown from './SingleChoiceDropdown';
import BehalfContactForm from './BehalfContactForm';


class BehalfForm extends React.Component {
  render() {
    const {
      formData,
      setFormData,
      handleInputChange,
      toggleValue,
      handleChoiceUpdate
    } = this.props; // Destructure props

    return (
      <>
        <SingleChoiceDropdown 
            value={formData.behalf}
            toggleValue={(e) => toggleValue(e, "behalf")} 
            label={"Is this gift on behalf of someone?"}
            identifier="behalf"
            outerClass="col-md-12"
        />

        {formData.behalf && (<BehalfContactForm
                        formData={formData}
                        handleInputChange={handleInputChange}
                        toggleValue={toggleValue}
                        handleChoiceUpdate={handleChoiceUpdate}
        />)}


      </>
    );
  }
}

export default BehalfForm;
