import React, { Component } from "react";
import "../assets/css/Placeholder.css";
import "../assets/css/responsive.css";
import Navbar from "./Navbar"
import Footer from "./Footer";
import BlueSection from "./BlueSection"

class Placeholder extends Component {
  render() {
    return (
      <>
      <BlueSection />
        <div className="donation-area bg-gray default-padding placeholder">
          {/* Fixed BG */}
          <Navbar />
          <div className="fixed-bg"></div>
          <div className="container">
            <div className="row">
            <div class="col-md-12 info text-light donation-form" style={{"textAlign": "center"}}>
            <h1 style={{
    color: 'white',
    fontWeight: 'bolder',
    fontSize: '3em', /* Increase font size */
    textShadow: '2px 2px 4px rgba(0, 0, 0, 0.9)' /* Add text shadow for emphasis */
}}>
    Welcome to Big Sisters' Future Donation Page!
</h1>
            </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

export default Placeholder;
