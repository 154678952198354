import React from 'react';
import Select from 'react-select'; // Import Select from react-select

const DROPDOWN_STYLE = {
  control: (baseStyles, state) => {
    return {
      ...baseStyles,
      borderWidth: "0px",
      minHeight: "0px",
      boxShadow: "0px",
    };
  },
  menu: (baseStyles, state) => {
    return {
      ...baseStyles,
      marginTop: "0px",
      marginBottom: "0px",
      boxShadow: "0px",
      fontSize: "0.9rem",
    };
  },
  option: (baseStyles, state) => {
    return {
      ...baseStyles,
      backgroundColor: state.isFocused ? "#DEEBFF" : "white",
      color: "inherit",
    };
  },
};


class CustomChoiceDropdown extends React.Component {



  render() {
    const {
      value,
      handleChoiceUpdate,
      label,
      identifier,
      outerClass,
      isRequired,
      choiceOptions,
      defaultValue
    } = this.props; // Destructure props

    const getOption = (choice) => {
      return choiceOptions.find(obj => obj.value === choice);
    }


    return (
      <>
          <div className={outerClass ? outerClass : "col-lg-6"}>
            <div className="form-group">
            <label htmlFor={identifier}>
  {label}
  {isRequired && <span className="required"> *</span>}
</label>

              <Select
                id={identifier}
                defaultValue={defaultValue}
                value={getOption(value)}
                className="form-control"
                options={choiceOptions}
                styles={DROPDOWN_STYLE}
                onChange={(selectedOption) => handleChoiceUpdate(selectedOption, identifier)}
              />
            </div>
          </div>

      </>
    );
  }
}

export default CustomChoiceDropdown;
