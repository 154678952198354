import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import DonationForm from './DonationForm';
import VirtualTerminalForm from './VirtualTerminalForm2';
import CobsCampaignForm from "./CobsCampaignForm"
import CampaignForm from "./CampaignForm"
import BookCampaignForm from './BookCampaignForm';
import SpringCampaignForm from "./SpringCampaignForm"

console.log(process.env.REACT_APP_STRIPE_KEY);

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const StripeContainer = ({ updateIsLoading, updateHasDonated, amount, updateAmount, formType, payFees, togglePayFees, updateFirstName }) => {
  // Render different types of DonationForms based on the 'formType' prop
  const renderDonationForm = () => {
    switch (formType) {
      case 'VirtualTerminal':
        return <VirtualTerminalForm 
                  updateIsLoading={updateIsLoading} 
                  updateHasDonated={updateHasDonated} 
                  amount={amount}
                  updateAmount={updateAmount} />;
        case 'Cobs':
                    return <CobsCampaignForm 
                        updateIsLoading={updateIsLoading} 
                        updateHasDonated={updateHasDonated} 
                        amount={amount}
                        updateAmount={updateAmount}
                        payFees={payFees}
                        togglePayFees={togglePayFees}
                        updateFirstName={updateFirstName} />;
        case 'Book':
          return <BookCampaignForm 
          updateIsLoading={updateIsLoading} 
          updateHasDonated={updateHasDonated} 
          amount={amount}
          updateAmount={updateAmount}
          payFees={payFees}
          togglePayFees={togglePayFees}
          updateFirstName={updateFirstName} />;
        case 'Spring':
          return <SpringCampaignForm 
          updateIsLoading={updateIsLoading} 
          updateHasDonated={updateHasDonated} 
          amount={amount}
          updateAmount={updateAmount}
          payFees={payFees}
          togglePayFees={togglePayFees}
          updateFirstName={updateFirstName} />;
      case 'Campaign':
        return <CampaignForm 
            updateIsLoading={updateIsLoading} 
            updateHasDonated={updateHasDonated} 
            amount={amount}
            updateAmount={updateAmount}
            payFees={payFees}
            togglePayFees={togglePayFees}
            updateFirstName={updateFirstName} />;
      // Add more cases for different types of DonationForms
      default:
        return <DonationForm 
                  updateIsLoading={updateIsLoading} 
                  updateHasDonated={updateHasDonated} 
                  amount={amount}
                  updateAmount={updateAmount}
                  payFees={payFees}
                  togglePayFees={togglePayFees}
                  updateFirstName={updateFirstName} />;
    }
  };

  return (
    <Elements stripe={stripePromise}>
      {renderDonationForm()}
    </Elements>
  );
};

export default StripeContainer;
