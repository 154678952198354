import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import './App.css';

import { Route, Routes } from "react-router-dom";
import DonationComponent from './components/DonationComponent'
import VirtualTerminalComponent from './components/VirtualTerminalComponent'
import Placeholder from './components/Placeholder';
import PlaceholderCampaign from './components/PlaceholderCampaign';
import CampaignComponent from './components/CampaignComponent';
import CobsCampaignComponent from './components/CobsCampaignComponent';
import BookCampaignComponent from './components/BookCampaignComponent';
import SpringCampaignComponent from './components/SpringCampaignComponent';

function App() {

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Placeholder />} />
        <Route path="/spring2024" element={<SpringCampaignComponent />} />
        <Route path="/mjibril" element={<BookCampaignComponent />} />
        <Route path="/cobs_panorama" element={<CobsCampaignComponent />} />
        <Route path="/preview/donate" element={<DonationComponent />} />
        <Route path="/monthly/preview" element={<DonationComponent />} />
        <Route path="/preview/campaign" element={<CampaignComponent />} />
        <Route path="/preview/virtualterminal" element={<VirtualTerminalComponent />} />
        <Route path="/preview/virtualterminal/marley" element={<VirtualTerminalComponent />} />
        <Route path="/preview/virtualterminal/nathania" element={<VirtualTerminalComponent />} />
        <Route path="/preview/virtualterminal/marni" element={<VirtualTerminalComponent />} />
        <Route path="/preview/virtualterminal/cindy" element={<VirtualTerminalComponent />} />
        <Route path="/preview/virtualterminal/chantelle" element={<VirtualTerminalComponent />} />
      </Routes>
    </div>
  );
}

export default App;
