import React from 'react';
import '../assets/css/FakeApplePayButton.css'; // Import your CSS file

const ApplePayButton = ({handleClick}) => {


  return (
    <div className="fake-apple-pay-button" onClick={handleClick}>
      <div alt="Apple Pay Logo" className="apple-pay-logo" />
 
    </div>
  );
};

export default ApplePayButton;
