import React from "react";
import SingleChoiceDropdown from "./SingleChoiceDropdown";

class BehalfContactForm extends React.Component {
  render() {
    const { formData, handleInputChange, toggleValue, handleChoiceUpdate } =
      this.props; // Destructure props

      const imageOptions = [
        { value: "TributeOption1", label: "Option 1", src: "/TributeOption1.png" },
        { value: "TributeOption2", label: "Option 2", src: "/TributeOption2.png" },
        { value: "TributeOption3", label: "Option 3", src: "/TributeOption3.png" },
        { value: "TributeOption4", label: "Option 4", src: "/TributeOption4.png" },
      ];

    return (
      <>
        <div className="col-lg-6">
          <div className="form-group">
            <label htmlFor="behalfFirstName">
              First Name <span className="required">*</span>
            </label>
            <input
              type="text"
              className="form-control"
              id="behalfFirstName"
              placeholder="First Name"
              value={formData.behalfFirstName}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className="col-lg-6">
          <div className="form-group">
            <label htmlFor="behalfLastName">
              Last Name <span className="required">*</span>
            </label>
            <input
              type="text"
              className="form-control"
              id="behalfLastName"
              placeholder="Last Name"
              value={formData.behalfLastName}
              onChange={handleInputChange}
            />
          </div>
        </div>

        <SingleChoiceDropdown 
            value={formData.ecard}
            toggleValue={(e) => toggleValue(e, "ecard")} 
            label={"Send an e-card?"}
            identifier="ecard"
            outerClass="col-md-12"
        />



        {formData.ecard && (
          <>
            <div className="col-md-12">
              <div className="form-group">
                <label htmlFor="behalfEmail">
                  Their Email <span className="required">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="behalfEmail"
                  placeholder="Email"
                  value={formData.behalfEmail}
                  onChange={handleInputChange}
                />
              </div>
            </div>

            <div className="col-md-12">
              <div className="form-group">
                <label htmlFor="behalfPhoneNumber">
                  Their Phone Number <span className="required">*</span>
                </label>

                <input
                  type="text"
                  className="form-control"
                  id="behalfPhoneNumber"
                  placeholder="Phone Number"
                  value={formData.behalfPhoneNumber}
                  onChange={handleInputChange}
                />
                <p style={{ color: "red", fontSize: "0.9em" }}>
                  Please provide their phone number and email so we can seamlessly notify
                  your gift recipient on your behalf.
                </p>
              </div>
            </div>

            <div className="col-md-12">
              <div className="form-group">
                    {/* Display image options */}
                    <label>Choose an image:</label>
                <div>
                <div style={{ display: "flex" }}>
                {imageOptions.map((imageOption) => (
                    <label key={imageOption.value} style={{ marginRight: "10px" }}>
                      <input
                        type="radio"
                        name="selectedImage"
                        value={imageOption.value}
                        checked={formData.behalfImageOption === imageOption.value}
                        onChange={(selectedOption) => handleChoiceUpdate(selectedOption.target, "behalfImageOption") }
                        style={{ display: "none" }}
                      />
                      <img src={imageOption.src} alt={imageOption.label} 
                      onClick={() => document.querySelector(`input[value="${imageOption.value}"]`).click()}
                      style={{
                        width: "100px",
                        cursor: "pointer",
                        border: formData.behalfImageOption === imageOption.value ? "10px solid #2dccd3" : "none"
                      }}
                      />
                    </label>
                  ))}
                </div>

                </div>
                </div></div>


                <div className="col-md-12">
          <div className="form-group">
            <label htmlFor="behalfNote">Message</label>
            <input
              type="text"
              className="form-control"
              id="behalfNote"
              placeholder="Your message"
              value={formData.behalfNote}
              onChange={handleInputChange}
            />
          </div>
        </div>

          </>
        )}


      </>
    );
  }
}

export default BehalfContactForm;
