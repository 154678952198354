import React from 'react';


class ContactForm extends React.Component {
  render() {
    const {
      formData,
      handleInputChange
    } = this.props; // Destructure props

    const lowerCaseHref = window.location.href.toLowerCase();

    return (
      <>
        <div className="col-lg-6">
          <div className="form-group">
            <label htmlFor="email">Email{' '}<span className="required">*</span></label>
            <input
              type="email"
              className="form-control"
              id="email"
              placeholder="Email"
              value={formData.email}
              onChange={handleInputChange}
              required={true}
            />
          </div>
        </div>
        <div className="col-lg-6">
          <div className="form-group">
            <label htmlFor="phoneNumber">Phone Number{' '}<span className="required">*</span></label>
            <input
              type="tel"
              className="form-control"
              id="phoneNumber"
              placeholder="Phone Number"
              value={formData.phoneNumber}
              onChange={handleInputChange}
              required={true}
            />
          </div>
        </div>

      </>
    );
  }
}

export default ContactForm;
