import React, { useState, useEffect } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  stateOptions,
  provinceOptions,
  initialState,
  CARD_ELEMENT_OPTIONS,
  monthlyInitialState,
} from "./constants";
import AddressForm from "./VirtualTerminal/AddressForm";
import ContactForm from "./VirtualTerminal/ContactForm";
import NameForm from "./VirtualTerminal/NameForm";
import BillingForm from "./BillingForm";
import NotifyContactForm from "./NotifyContactForm";
import TributeForm from "./TributeForm";
import AmountForm from "./AmountForm";
import { useLocation } from "react-router-dom";
import SingleChoiceDropdown from "./SingleChoiceDropdown";
import ClassificationForm from './ClassificationForm'
import ReceiptOptions from "./ReceiptOptions";

const VirtualTerminalForm = ({
  updateIsLoading,
  updateHasDonated,
  amount,
  updateAmount
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const location = useLocation();
  const currentURL = location.pathname;
  const isMonthly = currentURL.includes("/monthly");

  const [formData, setFormData] = useState(
    isMonthly ? monthlyInitialState : initialState
  );

  const [paymentRequest, setPaymentRequest] = useState(null);
  const [applePay, setApplePay] = useState(false);
  const [googlePay, setGooglePay] = useState(false);

  const getWelcomeMessage = () => {
    let message = "Virtual Terminal";
    const currentURL = window.location.href.toLowerCase(); // Convert URL to lowercase

    if (currentURL.includes("/marley")) {
      message = "Marley's Virtual Terminal";
    } else if (currentURL.includes("/nathania")) {
      message = "Nathania's Virtual Terminal";
    } else if (currentURL.includes("/marni")) {
      message = "Marni's Virtual Terminal";
    } else if (currentURL.includes("/cindy")) {
      message = "Cindy's Virtual Terminal";
    } else if (currentURL.includes("/chantelle")) {
      message = "Chantelle's Virtual Terminal";
    }

    return message;
  };

  const extractFirstAndLastName = (fullName) => {
    if (!fullName || typeof fullName !== "string" || fullName.trim() === "") {
      return { firstName: "", lastName: "" };
    }

    const names = fullName.trim().split(" ");
    const firstName = names[0];
    const lastName = names.length > 1 ? names[names.length - 1] : "";

    return { firstName, lastName };
  };

  useEffect(() => {
    if (!stripe || !elements) {
      return;
    }
    const pr = stripe.paymentRequest({
      country: "CA",
      currency: "cad",
      requestPayerEmail: true,
      requestPayerName: true,
      total: {
        label: "Donation to Big Sisters",
        amount: 1000,
      },
    });

    setApplePay(false);
    setGooglePay(false);

  }, [stripe, elements, amount, false, formData]);

  const roundCurrency = (amount) => {
    return Math.ceil(amount * 100) / 100;
  };

  const calculateStripeFees = (amount) => {
    let baseStripeFee = 2.9;
    const additionalInternationalFee = 0.8;

    if (formData.selectedCountry.value !== "CAN") {
      baseStripeFee += additionalInternationalFee;
    }

    const stripeFees = roundCurrency(amount * (baseStripeFee * 0.01) + 0.3);

    return stripeFees;
  };

  const calculateOurFees = (amount) => {
    return roundCurrency(amount * 0.7 * 0.01);
  };

  const calculateInitialAmountGuess = (amount) => {
    const totalAmount =
      amount + calculateStripeFees(amount) + calculateOurFees(amount);

    return totalAmount;
  };

  const calculateTotalAmount = (initialAmount, canPayFees) => {
    const amount = Number(initialAmount);

    if (isNaN(amount)) {
      return 0;
    }

    if (!canPayFees) {
      return amount;
    }

    const initialGuess = calculateInitialAmountGuess(amount);
    const stripeFees = calculateStripeFees(initialGuess);
    const ourFees = calculateOurFees(initialGuess);

    const remainingAmount = initialGuess - stripeFees - ourFees;

    if (remainingAmount < initialAmount) {
      return roundCurrency(initialGuess + (initialAmount - remainingAmount));
    }

    return roundCurrency(initialGuess);
  };

  const toggleValue = (e, label) => {
    console.log(e);
    console.log(label);

    setFormData({
      ...formData,
      [label]: !formData[label],
    });
  };

  const handleInputChange = (event) => {
    if (event.target.id === "amount") {
      updateAmount(event.target.value);
    } else {
      setFormData({
        ...formData,
        [event.target.id]: event.target.value,
      });
    }
  };

  const handleChoiceUpdate = (option, label) => {
    console.log(option.value);
    console.log(label);

    setFormData({
      ...formData,
      [label]: option.value,
    });
  };


  const handleUpdateReceipt = (selectedReceipt) => {
    setFormData({ ...formData, receipt:selectedReceipt });
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    updateIsLoading(true);

    if (!stripe || !elements) {
      toast.error("Something went wrong. Please refresh the page.");
      updateIsLoading(false);
      return;
    }

    const cardElement = elements.getElement(CardElement);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
    });

    if (error) {
      console.error(error);
      toast.error(
        "Payment failed. Please try again or use a different credit card."
      );
      updateIsLoading(false);
      return;
    }

    const originURL = window.location.href;

    try {
      let body = { ...formData };
      body["paymentMethodId"] = paymentMethod.id;
      body["province"] = body.selectedProvince.value;
      body["country"] = body.selectedCountry.value;
      body["selectedWording"] = body.selectedWording.value;
      body["provinceOptions"] = "";
      body["amount"] = calculateTotalAmount(amount, false);
      body["billingProvince"] = body.billingSelectedProvince.value;
      body["billingCountry"] = body.billingSelectedCountry.value;
      body["originalAmount"] = amount;
      body["payFees"] = false;
      body["originURL"] = originURL;

      const endpoint = formData.monthly ? "subscriptions/virtualterminal" : "payments/virtualterminal";

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/${endpoint}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );

      if (!response.ok) {
        toast.error("Payment Error! Please try again later.");
      } else {
        const data = await response.json();
        console.log(data); // Success message from backend

        setFormData(initialState);

        updateHasDonated(true);
      }
      updateIsLoading(false);
    } catch (error) {
      console.error(error);
      toast.error("Payment failed. Please try again.");
      updateIsLoading(false);
    }
  };

  const handleCountryChange = (selectedOption) => {
    let newLabel = "";
    let options = [];
    let newZipLabel = "";

    if (selectedOption.value === "USA") {
      newLabel = "State";
      options = stateOptions;
      newZipLabel = "Zip Code";
    } else if (selectedOption.value === "CAN") {
      newLabel = "Province";
      options = provinceOptions;
      newZipLabel = "Postal Code";
    }

    const updatedState = {
      selectedCountry: selectedOption,
    };

    if (newLabel !== formData.provinceLabel) {
      updatedState.provinceLabel = newLabel;
      updatedState.provinceOptions = options;
      updatedState.selectedProvince = options[0];
      updatedState.zipLabel = newZipLabel;
    }

    setFormData({ ...formData, ...updatedState });
  };

  const handleBillingProvinceChange = (selectedOption) => {
    setFormData({ ...formData, billingSelectedProvince: selectedOption });
  };

  const handleBillingCountryChange = (selectedOption) => {
    let newLabel = "";
    let options = [];
    let newZipLabel = "";

    if (selectedOption.value === "USA") {
      newLabel = "State";
      options = stateOptions;
      newZipLabel = "Zip Code";
    } else if (selectedOption.value === "CAN") {
      newLabel = "Province";
      options = provinceOptions;
      newZipLabel = "Postal Code";
    }

    const updatedState = {
      billingSelectedCountry: selectedOption,
    };

    if (newLabel !== formData.billingProvinceLabel) {
      updatedState.billingProvinceLabel = newLabel;
      updatedState.billingProvinceOptions = options;
      updatedState.billingSelectedProvince = options[0];
      updatedState.billingZipLabel = newZipLabel;
    }

    setFormData({ ...formData, ...updatedState });
  };

  const handleProvinceChange = (selectedOption) => {
    setFormData({ ...formData, selectedProvince: selectedOption });
  };

  return (
    <>
      <div className="form-box">
        <form onSubmit={handleSubmit}>
          <h3 style={{ color: "#2dccd3" }}>{getWelcomeMessage()}</h3>

          <div className="row  text-light">
            <AmountForm
              handleInputChange={handleInputChange}
              formData={formData}
              toggleValue={toggleValue}
              isMonthly={isMonthly}
              amount={amount}
            />

            <ClassificationForm
              handleInputChange={handleInputChange}
              formData={formData}
            />

            <ReceiptOptions selectedType={formData.receipt} 
            handleTypeClick={handleUpdateReceipt} />

            <NameForm
              formData={formData}
              handleInputChange={handleInputChange}
              handleProvinceChange={handleProvinceChange}
              toggleValue={toggleValue}
              handleCountryChange={handleCountryChange}
              handleBillingProvinceChange={handleBillingProvinceChange}
              handleBillingCountryChange={handleBillingCountryChange}
              creditCardSectionTitle={
                applePay || googlePay
                  ? "Or Credit Card Payment"
                  : "Credit Card Payment"
              }
            />

            <ContactForm
              formData={formData}
              handleInputChange={handleInputChange}
              handleProvinceChange={handleProvinceChange}
              toggleValue={toggleValue}
              handleCountryChange={handleCountryChange}
              handleBillingProvinceChange={handleBillingProvinceChange}
              handleBillingCountryChange={handleBillingCountryChange}
            />

            <div className="col-md-12">
              <div className="form-group">
                <label htmlFor="cardNumber">
                  Donate by Credit Card <span className="required">*</span>
                </label>
                <div className="input-group">
                  <CardElement
                    className="form-control"
                    options={CARD_ELEMENT_OPTIONS}
                  />
                </div>
              </div>
            </div>

            <AddressForm
              formData={formData}
              handleInputChange={handleInputChange}
              handleProvinceChange={handleProvinceChange}
              toggleValue={toggleValue}
              handleCountryChange={handleCountryChange}
              handleBillingProvinceChange={handleBillingProvinceChange}
              handleBillingCountryChange={handleBillingCountryChange}
            />

            <BillingForm
              formData={formData}
              handleInputChange={handleInputChange}
              handleProvinceChange={handleProvinceChange}
              toggleValue={toggleValue}
              handleCountryChange={handleCountryChange}
              handleBillingProvinceChange={handleBillingProvinceChange}
              handleBillingCountryChange={handleBillingCountryChange}
            />

            {!formData.monthly && (
              <>
                <SingleChoiceDropdown
                  value={formData.anonymous}
                  toggleValue={(e) => toggleValue(e, "anonymous")}
                  label="Make it anonymous?"
                  identifier="anonymous"
                  outerClass="col-md-12"
                />
              </>
            )}

            <SingleChoiceDropdown
              value={formData.tribute}
              toggleValue={(e) => toggleValue(e, "tribute")}
              label="Is it a tribute?"
              identifier="tribute"
              outerClass="col-md-12"
            />

            {formData.tribute && (
              <TributeForm
                formData={formData}
                setFormData={setFormData}
                handleInputChange={handleInputChange}
                toggleValue={toggleValue}
              />
            )}

            {formData.notify && (
              <NotifyContactForm
                formData={formData}
                handleInputChange={handleInputChange}
                handleProvinceChange={handleProvinceChange}
                toggleValue={toggleValue}
                handleCountryChange={handleCountryChange}
                handleChoiceUpdate={handleChoiceUpdate}
              />
            )}

            <button className="btn btn-theme effect btn-md" type="submit">
              Start Payment Process
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default VirtualTerminalForm;