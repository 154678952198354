import React, { Component } from "react";
import "../assets/css/ThankYouPage.css";

class ThankYouPage extends Component {
  handleDonateAgain = (e) => {
    e.preventDefault();

    this.props.updateHasDonated(false);
  }

  handleContinue = (e) => {
    e.preventDefault();
    window.location.href = "https://www.bigsisters.bc.ca/"; // Redirect to Big Sisters website
  }

  render() {
    return (
      <div className="thank-you-page">
        <div className="form-box">
          <form>
            <h3 style={{color: '#2dccd3'}}>{this.props.firstName ? <>Thank You, {this.props.firstName}!</> : <>Thank You!</>}</h3>
            <p style={{color:"#232323"}}>
              Our donors make Big Sisters possible, and every little bit makes
              a big difference. Your donation of ${this.props.amount} will help
              us continue to show youth in our community that all children
              deserve to reach their full potential.
            </p>

            <button style={{textTransform: "none"}} className="btn btn-theme effect btn-md continue-on" type="submit"  onClick={this.handleContinue}>
            Learn More About Big Sisters of BC Lower Mainland
            </button>

            <button className="btn btn-theme effect btn-md donate-again" type="submit" 
            onClick={this.handleDonateAgain}>
            Donate Again
            </button>



          </form>
        </div>
      </div>
    );
  }
}

export default ThankYouPage;
