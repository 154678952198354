import React from 'react';
import Select from 'react-select'; // Assuming the Select component is from a library like react-select
import { DROPDOWN_STYLE, wordingOptions } from './constants';
import SingleOption from './SingleOption';
import SingleChoiceDropdown from './SingleChoiceDropdown';


class TributeForm extends React.Component {
  render() {
    const {
      formData,
      setFormData,
      handleInputChange,
      toggleValue,
    } = this.props; // Destructure props

    return (
      <>
        <div className="col-md-12">
          <div className="form-group">
            <label htmlFor="wording">This gift is... <span className="required">*</span></label>
            <Select
              id="wording"
              defaultValue={formData.selectedWording}
              value={formData.selectedWording}
              className="form-control"
              options={wordingOptions}
              styles={DROPDOWN_STYLE}
              onChange={(selectedOption) => {setFormData({ ...formData, selectedWording: selectedOption })}}
            />
          </div>
        </div>

        <div className="col-lg-6">
          <div className="form-group">
            <label htmlFor="tributeFirstName">First Name <span className="required">*</span></label>
            <input
              type="text"
              className="form-control"
              id="tributeFirstName"
              placeholder="First Name"
              value={formData.tributeFirstName}
              onChange={handleInputChange}
            />
          </div>
        </div>

        <div className="col-lg-6">
          <div className="form-group">
            <label htmlFor="tributeLastName">Last Name <span className="required">*</span></label>
            <input
              type="text"
              className="form-control"
              id="tributeLastName"
              placeholder="Last Name"
              value={formData.tributeLastName}
              onChange={handleInputChange}
            />
          </div>
        </div>

        <SingleChoiceDropdown 
                  value={formData.notify}
                  toggleValue={(e) => toggleValue(e, "notify")} 
                  label={"Do you want to notify someone about your gift?"}
                  identifier="notify"
                  outerClass="col-md-12"
            />
      </>
    );
  }
}

export default TributeForm;
