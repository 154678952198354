import React from "react";
import Form from "react-bootstrap/Form";
import '../assets/css/ClassificationForm.css';


class ClassificationForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      appealOptions: [
        "GALA2024",
        "SPRINGASK2024",
        "YEARENDASK2024",
        "GRAPEJUICE2024",
"GIVINGTREE2024",
"COBS2024",
"MJIBRIL2024"
      ],
      campaignOptions: [
        "Unleashed",
        "Unassigned"
      ],
      fundOptions: [
        "Unrestricted",
        "Big Sisters",
        "Study Buddy",
        "Group Mentoring",
        "Sustainability"
      ],
      packageOptions: [
        "Auction Donation",
        "Auction Purchase",
        "Email 1",
        "Giving Level Package"
      ]
    };
  }

  render() {
    const { handleInputChange, formData } = this.props;
    const dropdownStyle = { textAlign: "center" };

    return (
      <div className="classification">

        <div className="col-md-12">
          <div className="form-group">
            <label htmlFor="fund">Fund</label>
            <div className="dropdown input-group">
              <Form.Control
                id="fund"
                as="select"
                value={formData.fund}
                onChange={handleInputChange}
                style={dropdownStyle}
              >
                <option value="" disabled hidden style={{ color: "#999" }}>
                  &#x2015; Choose a fund &#x2015;
                </option>
                {this.state.fundOptions.map((fund, index) => (
                  <>
                    <option key={fund} value={fund}>
                      {fund}
                    </option>
                  </>
                ))}
              </Form.Control>
            </div>
          </div>
        </div>

        <div className="col-md-12">
          <div className="form-group">
            <label htmlFor="appeal">Appeal</label>
            <div className="dropdown input-group">
              <Form.Control
                id="appeal"
                as="select"
                value={formData.appeal}
                onChange={handleInputChange}
                style={dropdownStyle}
              >
                <option value="" disabled hidden style={{ color: "#999" }}>
                  &#x2015; Choose an appeal &#x2015;
                </option>
                {this.state.appealOptions.map((appeal, index) => (
                  <>
                    <option key={appeal} value={appeal}>
                      {appeal}
                    </option>
                  </>
                ))}
              </Form.Control>
            </div>
          </div>
        </div>


        <div className="col-md-12">
          <div className="form-group">
            <label htmlFor="campaign">Campaign</label>
            <div className="dropdown input-group">
              <Form.Control
                id="campaign"
                as="select"
                value={formData.campaign}
                onChange={handleInputChange}
                style={dropdownStyle}
              >
                <option value="" disabled hidden style={{ color: "#999" }}>
                  &#x2015; Choose a campaign &#x2015;
                </option>
                {this.state.campaignOptions.map((campaign, index) => (
                  <>
                    <option key={campaign} value={campaign}>
                      {campaign}
                    </option>
                  </>
                ))}
              </Form.Control>
            </div>
          </div>
        </div>

        <div className="col-md-12">
          <div className="form-group">
            <label htmlFor="package">Package</label>
            <div className="dropdown input-group">
              <Form.Control
                id="package"
                as="select"
                value={formData.package}
                onChange={handleInputChange}
                style={dropdownStyle}
              >
                <option value="" disabled hidden style={{ color: "#999" }}>
                  &#x2015; Choose a package &#x2015;
                </option>
                {this.state.packageOptions.map((myPackage, index) => (
                  <>
                    <option key={myPackage} value={myPackage}>
                      {myPackage}
                    </option>
                  </>
                ))}
              </Form.Control>
            </div>
          </div>
        </div>


      </div>
    );
  }
}

export default ClassificationForm;
