// BlueSection.js
import React, { Component } from "react";
import "../assets/css/BlueSection.css"; // Import the CSS file

class BlueSection extends Component {
  constructor(props) {
    super(props);

    this.state = {
      shouldSlideUp: false,
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = () => {
    const currentPosition = window.pageYOffset;
    if (currentPosition > 0 && !this.state.shouldSlideUp) {
      this.setState({ shouldSlideUp: true });
    } else if (currentPosition === 0 && this.state.shouldSlideUp) {
      this.setState({ shouldSlideUp: false });
    }
  };

  render() {
    const { shouldSlideUp } = this.state;
    const blueSectionClasses = `thin-blue-section ${shouldSlideUp ? 'scrolled-up' : ''}`;

    return (
      <>
        <div className={blueSectionClasses}></div>
      </>
    );
  }
}

export default BlueSection;
