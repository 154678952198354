import React from "react";
import "../assets/css/SingleOption.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquare } from "@fortawesome/free-regular-svg-icons";
import { faSquareCheck } from "@fortawesome/free-solid-svg-icons";

class SingleOption extends React.Component {
  render() {
    return (
      <div className="causes-area causes-single-items">
        <div className="col-lg-6 item">
          <ul style={{ paddingLeft: 0 }}>
            <li>
              <button
                style={{
                  marginTop: "0px",
                  padding: "10px 20px",
                  position: "relative",
                  display: "flex", // Use flexbox
                  alignItems: "center", // Align items vertically in the center
                  color: "#54585a"
                }}                type="button"
                className={`form-btn btn-md ${
                  this.props.value ? "active" : "inactive"
                }`}
                onClick={() => this.props.toggleValue()}
              >
                <span className="button-content">
                  <FontAwesomeIcon icon={this.props.value ? faSquareCheck : faSquare} className="checkbox-icon icon-lg" />
                </span>
                <span>&nbsp;{this.props.label}</span>
              </button>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

export default SingleOption;
