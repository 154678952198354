import React from "react";
import "../assets/css/DonationOptions.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquare } from "@fortawesome/free-regular-svg-icons";
import { faSquareCheck } from "@fortawesome/free-solid-svg-icons";

class DonationOptions extends React.Component {
  renderButtons = () => {
    const amounts = [25, 50, 100, 250, 500, ""];

    return (
      <>
        {amounts.map((amount, index) => (
          <li key={index}>
            <button
              type="button"
              className={`form-btn ${
                (this.props.amount === amount)  || (amount === "" && !amounts.includes(this.props.amount) ) ? "active" : "inactive"
              }`}
              onClick={() => this.props.handleAmountClick(amount)}
            >
              <span className="button-content">
                <FontAwesomeIcon
                  icon={(this.props.amount === amount) || (amount === "" && !amounts.includes(this.props.amount) ) ? faSquareCheck : faSquare}
                  className="checkbox-icon icon-lg"
                />
                { amount === "" ? (<>&nbsp;Other</>) : (<>&nbsp;${amount}</>)}
              </span>
            </button>
          </li>
        ))}
      </>
    );
  };

  render() {
    return (
      <div className="row causes-area causes-single-items">
        <div className="col-md-12 item">
          <ul className="group">{this.renderButtons()}</ul>
        </div>
      </div>
    );
  }
}

export default DonationOptions;
